$( document ).ready(function() {

    var imgReveal = {
        origin: 'bottom',
        duration: 600,
        distance : '16px',
        easing   : 'ease-in-out',
        scale: 1,
        opacity: 0
    };

    var titleReveal = {
        origin: 'bottom',
        duration: 600,
        distance : '10px',
        easing   : 'ease-in-out',
        scale: 1,
        opacity: 0
    };

    window.sr = ScrollReveal();
    sr.reveal('.reveal', imgReveal);
    sr.reveal('.title-reveal', titleReveal);



    if ( ! Modernizr.objectfit ) {
        $('.mission').each(function () {
            var $container = $(this),
                imgUrl = $container.find('img').prop('src');
            if (imgUrl) {
                $container
                    .css('backgroundImage', 'url(' + imgUrl + ')')
                    .addClass('compat-object-fit');
            }
        });
    }



    $('a[href^=\\#]').each(function () {
        $(this).click(function (event) {

            event.preventDefault();

            var to = $.attr(this, 'href');
            if ($(to).offset()) {
                $('html, body').animate({
                    scrollTop: ($(to).offset().top)
                }, 2000, 'easeInOutExpo', function () {
                });
            }
            return false;
        });
    });

});